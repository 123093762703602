module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kunal Sinha Profile","short_name":"Kunal Sinha Profile","start_url":"/","background_color":"#0d182e","theme_color":"#0d182e","display":"minimal-ui","icon":"src/images/icon2.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"50e9c6907fe91e71c385c180621e1110"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
